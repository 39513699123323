import React, { useEffect, useState } from "react";
import Datepicker from "react-tailwindcss-datepicker";
import { format } from "date-fns";
import MCAddDataSummary from "./MCAddDataSummary";
import { generalInput, monthNumberToAbbreviation, positiveButton } from "../../../components/DesignStandardize";
import SeeTableSummary from "../../../SeeTable/components/SeeTableSummary";
import axios from "axios";
import crypto from "crypto-browserify";
const MCAddData = (id) => {
  const [awb, setAwb] = useState("");
  const [weight, setWeight] = useState(0);
  const [item, setItem] = useState("");
  const [frCost, setFrCost] = useState(0);
  const [contact, setContact] = useState("");
  // const [frCostInBDT, setFrCostInBDT] = useState(0);
  const [showLocationFields, setShowLocationFields] = useState(false);
  const [cityName, setCityName] = useState("");
  const [locationCost, setLocationCost] = useState(0);
  const [no, setNo] = useState("");
  const [flight, setFlight] = useState("");
  const [mawb, setMawb] = useState("");
  const [shipper, setShipper] = useState("");
  const [person, setPerson] = useState("");
  const [number, setNumber] = useState("");
  const [area, setArea] = useState("");
  const [nop, setNop] = useState("");
  const [spxType, setSpxType] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [deliveryItem, setDeliveryItem] = useState("");
  const [volumeWeight, setVolumeWeight] = useState(0);
  const [time, setTime] = useState("");
  const [receiving, setReceiving] = useState("");
  const [remarks, setRemarks] = useState("");
  const [dollarRate, setDollarRate] = useState(0);
  const [paidDate, setPaidDate] = useState("");
  const [actualPaid, setActualPaid] = useState("");
  const [locationclicker, setLocationClicker] = useState(false);
  const [selectedArea, setSelectedArea] = useState("");
  // const [locationclicker, setLocationChecker] = useState(false);
  let customerName = '';
// customer name api call kora lagbe
const [locations, setLocations] = useState([]);
// const handlelocationcost = () => {
//   setLocationClicker(!locationclicker);
// };
const [checkbox, setCheckbox] = useState(false);
const handleCheckboxChangeCod = () => {
  setCheckbox(!checkbox);
};
//cons
  const handleWeightChange = (e) => {
    const newWeight = e.target.value;
    if (newWeight >= 0) {
      setWeight(newWeight);
    } else {
      console.error("Weight must be greater than to 0");
    }
  };

  const handleFrCostChange = (e) => {
    const newFrCost = e.target.value;
    if (!isNaN(newFrCost) && newFrCost >= 0) {
      setFrCost(newFrCost);
    } else {
      console.error("Fr. cost must be a number greater than or equal to 0");
    }
  };

  const handleLocationCostChange = (e) => {
    const newLocCost = e.target.value;
    if (!isNaN(newLocCost) && newLocCost >= 0) {
      setLocationCost(newLocCost);
    } else {
      console.error(
        "Location cost must be a number greater than or equal to 0"
      );
    }
  };

  const handleCheckboxChange = (e) => {
    setShowLocationFields(e.target.checked);
  };
  const [valueDate, setValueDate] = useState([
    { startDate: null, endDate: null },
  ]);

  const handleDateChange = (newDate) => {
    console.log("startDate:", newDate.startDate);
    setValueDate(newDate);
  };

  // 10-11-2023 to 10-Nov-2023 conversion
  const formattedDate = valueDate.startDate
    ? format(new Date(valueDate.startDate), "dd-MMM-yyyy")
    : null;

  // 10-Nov-2023 to Nov conversion
  const month = valueDate.startDate
    ? format(new Date(valueDate.startDate), "MMM")
    : null;

  // Dollar to BDT conversion
  // useEffect(() => {
  //   if (frCost && !isNaN(frCost)) {
  //     fetch(`https://api.exchangerate-api.com/v4/latest/USD`)
  //       .then((response) => response.json())
  //       .then((data) => {
  //         const exchangeRate = data.rates.BDT;
  //         const convertedFrCost = parseFloat(frCost) * exchangeRate;
  //         const roundedFrCostInBDT = parseFloat(convertedFrCost.toFixed(2));
  //         setFrCostInBDT(roundedFrCostInBDT);
  //       })
  //       .catch((error) => {
  //         console.log("Error fetching exchange rates: ", error);
  //       });
  //   } else {
  //     setFrCostInBDT(0);
  //   }
  // }, [frCost]);

  async function getCustomerName(id) {
    try{
      const response = await axios.get(
        `http://localhost:4000/custTable/getCustomerNameByID/${id}`
      );
      console.log("Customer name: ", response.data.customerName);
      customerName = response.data.customerName;
      console.log(customerName);
    } catch (err){

    }
  }
  // useEffect(()=>{
  //    getCustomerName(id.id.CustomerID);
  // },[])
  // const compName = getCustomerName(id);
  // console.log(compName);
  const handlelocationcost = () => {
    setLocationClicker(!locationclicker);
  };

  async function getLocationName() {
    try {
      const response = await axios.get(
        `http://localhost:4000/maintable/getLocationName`
      );
      console.log("location Name info:", response.data);
      setLocations(response.data.rows);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    getLocationName();
  }, []);

  async function getDiffrateID(custID) {
    try {
      const response = await axios.get(
        `http://localhost:4000/getDiffRateID/getDiffRateID?customerID=${custID}`
      );
      return response.data.DiffRateID;
    } catch (error) {}
  }

async function getDiffRateArr(CustomerID) {
    try {
      const response = await axios.get(
        `http://localhost:4000/prepaid/diffRateArr?CustomerID=${CustomerID}`
      );

      console.log('getDiffRateArr',response.data);
      await sortAndSearch(response.data, roundWeight);
      await getPrice(roundWeight, price); // to find CUSTOM
      totalPrice = customPrice + frCostInBDT + locationCost;
    } catch (err) {
      console.error(err);
    }
  }

  let price = 0;
  let totalPrice = 0;
  let customPrice = 0;
  async function getPrice(weight, price) {
    customPrice = weight * price;
    return customPrice;
  }
  
  const roundWeight = Math.ceil(weight);
  async function sortAndSearch(arr, roundWeight) {
    for (let i = 0; i < arr[0].length; i++) {
      if (roundWeight <= arr[0][i]) {
        const calculatedPrice = arr[1][i];
        price = calculatedPrice;
        return;
      } else if (arr[0][i] === 0) {
        const calculatedPrice = arr[1][i - 1];
        price = calculatedPrice;
        return;
      }
    }
    // If ROUNDWGT is greater than all values in arr[0], return the last value in arr[1].
    const calculatedPrice = arr[1][arr[1].length - 1];
    price = calculatedPrice;
  }


//get location info (based on insertion)
async function getLocation(locationName) {
  const encryptedID = crypto
    .createHash("sha256")
    .update(locationName.toUpperCase())
    .digest("hex");
  try {
    const response = await axios.get(
      `http://localhost:4000/maintable/getLocation?encryptedID=${encryptedID}`
    );
    console.log("location info:", response.data);

    await sortAndSearch(response.data, roundWeight);

    await getPrice(roundWeight, price); // to find CUSTOM

    totalPrice = customPrice + frCostInBDT + locationCost;
  } catch (err) {
    console.error(err);
  }
}
  let custID;
  async function getCustomerID(encryptedID) {
    try {
      const response = await axios.get(
        `http://localhost:4000/prepaid/getCustomerID?EncryptedID=${encryptedID}`
      );
      return response.data.customerID;
    } catch (err) {
      console.error(err);
    }
  }
  const dollarrates = () => {
    let frCostInBDT;
    if (checkbox) {
      return (frCostInBDT = frCost * dollarRate + 0.1 * frCost * dollarRate);
    } else {
      return (frCostInBDT = frCost * dollarRate);
    }
  };

  const frCostInBDT = dollarrates();
  const handleSelectArea = (e) => {
    const selected = e.target.value;
    setSelectedArea(selected);
  };

  console.log(id.id.CustomerID);
  const uploadMainTable = async () => {
    const [day, monthNumber, year] = formattedDate?.split(".") || [];
    const currentYear = new Date().getFullYear();
    const currentCentury = Math.floor(currentYear / 100) * 100;
    const fourDigitYear = currentCentury + parseInt(year);

    console.log('fourDigitYear', fourDigitYear);
    const month = monthNumberToAbbreviation[monthNumber];

    if (locationclicker) await getLocation(selectedArea);
    else await getDiffRateArr(id.id.CustomerID);

    const diffRate = await getDiffrateID(id.id.CustomerID);
    await getCustomerName(id.id.CustomerID);
    const data = {
      CustomerID: id.id.CustomerID,
      DiffRateID: diffRate,
      NO: no,
      DATE: formattedDate,
      MONTH: month,
      YEAR: fourDigitYear,
      FLIGHT: flight,
      MAWB: mawb,
      AWB: awb,
      SHIPPER: shipper,
      COMPANY: customerName,
      PERSON: person,
      NUMBER: number,
      AREA: locationclicker ? selectedArea : area,
      NOP: nop,
      WGT: weight,
      SPXTYPE: spxType,
      PAYMENTMETH: paymentMethod,
      DELIVERYITEM: deliveryItem,
      VOLUMEWEIGHT: volumeWeight,
      TIME: time,
      RECEIVING: receiving,
      REMARKS: remarks,
      ROUNDWGT: roundWeight,
      FrCost$: frCost,
      FrCostBDT: frCostInBDT, // convert it from USD (rate will be given by user)
      CUSTOM: customPrice,
      TOTAL: totalPrice,
      PAIDDATE: paidDate,
      ACTUALPAID: actualPaid,
    };

    try {
      const response = await axios.post(
        "http://localhost:4000/table/tableinsertion",
        data
      );
      // window.location.reload();
    } catch (err) {
      console.error("error:", err);
    }
  };

  const handleAddData = (e) => {
    e.preventDefault();
    uploadMainTable();
  };

const summary = [
    { title: "No.", value: no },
    { title: "Date", value: formattedDate },
    { title: "Flight", value: flight },
    { title: "MAWB", value: mawb },
    { title: "AWB", value: awb },
    { title: "Shipper", value: shipper },
    // { title: "Company name", value: customerName },
    { title: "Contact Person", value: person },
    { title: "Contact Number", value: number },
    { title: "Area", value: locationclicker ? selectedArea : area },
    { title: "NOP", value: nop },
    { title: "WGT", value: weight },
    { title: "SPX type", value: spxType },
    { title: "Type of Payment", value: paymentMethod },
    { title: "Delivery item", value: deliveryItem },
    { title: "Volumw weight", value: volumeWeight },
    { title: "Time", value: time },
    { title: "Receiving Person", value: receiving },
    { title: "Remarks", value: remarks },
    { title: "Round Weight", value: roundWeight },
    { title: "Fr. Cost ($)", value: frCost },
    { title: "Fr. Cost (TK)", value: frCostInBDT },
    { title: "Custom", value: "" },
    { title: "Total", value: "" },
    { title: "Paid Date", value: paidDate },
    { title: "Actual Date", value: actualPaid },
  ];
  return (
    <>{/* Input fields */}
    {/* <p> Add data for {compname}</p> */}
        <form
            onSubmit={handleAddData}
            className="flex flex-row flex-wrap gap-10"
          >
            <div className="mx-auto grid grid-cols-1 lg:grid-cols-2 gap-y-3 gap-x-10">
              {/* NO */}
              <div className="w-64">
                <label className="label">NO.</label>
                <input
                  type="text"
                  placeholder="Type here"
                  className={`${generalInput}`}
                  value={no}
                  onChange={(e) => setNo(e.target.value)}
                />
              </div>

              {/* Flight */}
              <div className="w-64">
                <label className="label">Flight</label>
                <input
                  type="text"
                  placeholder="Type here"
                  className={`${generalInput}`}
                  value={flight}
                  onChange={(e) => setFlight(e.target.value)}
                />
              </div>

              {/* Date */}
              <div className="w-64">
                <label className="label">Date</label>
                <Datepicker
                  useRange={false}
                  primaryColor={"blue"}
                  value={valueDate}
                  onChange={handleDateChange}
                  asSingle={true}
                  displayFormat={"DD/MM/YYYY"}
                  inputClassName="w-full rounded-md bg-gray-200 focus:bg-white p-2"
                />
              </div>

              {/* MAWB */}
              <div className="w-64">
                <label className="label">MAWB</label>
                <input
                  type="text"
                  placeholder="Type here"
                  className={`${generalInput}`}
                  value={mawb}
                  onChange={(e) => setMawb(e.target.value)}
                />
              </div>

              {/* AWB */}
              <div className="w-64">
                <label className="label">AWB</label>
                <input
                  type="text"
                  placeholder="Type here"
                  className={`${generalInput}`}
                  value={awb}
                  onChange={(e) => setAwb(e.target.value)}
                />
              </div>

              {/* Shipper */}
              <div className="w-64">
                <label className="label">Shipper</label>
                <input
                  type="text"
                  placeholder="Type here"
                  className={`${generalInput}`}
                  value={shipper}
                  onChange={(e) => setShipper(e.target.value)}
                />
              </div>

              {/* Person */}
              <div className="w-64">
                <label className="label">Person</label>
                <input
                  type="text"
                  placeholder="Type here"
                  className={`${generalInput}`}
                  value={person}
                  onChange={(e) => setPerson(e.target.value)}
                />
              </div>

              {/* Number */}
              <div className="w-64">
                <label className="label">Number</label>
                <input
                  type="text"
                  placeholder="Type here"
                  className={`${generalInput}`}
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                />
              </div>

              {/* Area */}
              <div>
                {locationclicker ? (
                  <><label className="label">Area</label>
                  <select
                    className="select select-bordered select-sm w-64 h-[48px] bg-gray-100 focus:bg-white"
                    onChange={handleSelectArea}
                    value={selectedArea}
                  >
                    <option value="" disabled>
                      Select an Area
                    </option>
                    {locations.map((loc, id) => (
                      <option
                        key={id}
                        value={locationclicker ? loc.locationName : ""}
                      >
                        {loc.locationName}
                      </option>
                    ))}
                  </select></>
                ) : (
                  <div className="w-64">
                    <label className="label">Area</label>
                    <input
                      type="text"
                      placeholder="Type here"
                      className={`${generalInput}`}
                      value={area}
                      onChange={(e) => setArea(e.target.value)}
                    />
                  </div>
                )}
                <div>
                  <label className="label w-max">
                    <input
                      type="checkbox"
                      className="checkbox checkbox-sm md:checkbox-md"
                      onChange={handlelocationcost}
                    />
                    <span className="label-text ml-2">Add Location Cost</span>
                  </label>
                </div>
              </div>

              {/* NOP */}
              <div className="w-64">
                <label className="label">NOP</label>
                <input
                  type="text"
                  placeholder="Type here"
                  className={`${generalInput}`}
                  required
                  value={nop}
                  onChange={(e) => setNop(e.target.value)}
                />
              </div>

              {/* Weight */}
              <div className="w-64">
                <label className="label">Weight</label>
                <input
                  type="text"
                  placeholder="Type here"
                  className={`${generalInput}`}
                  required
                  value={weight}
                  onChange={(e) => setWeight(e.target.value)}
                />
              </div>

              {/* SPX type */}
              <div className="w-64">
                <label className="label">SPX type</label>
                <input
                  type="text"
                  placeholder="Type here"
                  className={`${generalInput}`}
                  value={spxType}
                  onChange={(e) => setSpxType(e.target.value)}
                />
              </div>

              {/* Payment method */}
              <div className="w-64">
                <label className="label">Payment method</label>
                <input
                  type="text"
                  placeholder="Type here"
                  className={`${generalInput}`}
                  value={paymentMethod}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                />
              </div>

              {/* Delivery Item */}
              <div className="w-64">
                <label className="label">Delivery Item</label>
                <input
                  type="text"
                  placeholder="Type here"
                  className={`${generalInput}`}
                  value={deliveryItem}
                  onChange={(e) => setDeliveryItem(e.target.value)}
                />
              </div>

              {/* Volume weight */}
              <div className="w-64">
                <label className="label">Volume Weight</label>
                <input
                  type="text"
                  placeholder="Type here"
                  className={`${generalInput}`}
                  value={volumeWeight}
                  onChange={(e) => setVolumeWeight(e.target.value)}
                />
              </div>

              {/* Time */}
              <div className="w-64">
                <label className="label">Time</label>
                <input
                  type="text"
                  placeholder="Type here"
                  className={`${generalInput}`}
                  value={time}
                  onChange={(e) => setTime(e.target.value)}
                />
              </div>

              {/* Receiving */}
              <div className="w-64">
                <label className="label">Receiving</label>
                <input
                  type="text"
                  placeholder="Type here"
                  className={`${generalInput}`}
                  value={receiving}
                  onChange={(e) => setReceiving(e.target.value)}
                />
              </div>

              {/* Remarks */}
              <div className="w-64">
                <label className="label">Remarks</label>
                <input
                  type="text"
                  placeholder="Type here"
                  className={`${generalInput}`}
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                />
              </div>

              {/* Convertion rate from dollar to bdt */}
              <div className="w-64">
                <label className="label">Current Dollar Rate</label>
                <input
                  type="text"
                  placeholder="Type here"
                  className={`${generalInput}`}
                  value={dollarRate}
                  onChange={(e) => setDollarRate(e.target.value)}
                />
              </div>

              {/* Fr cost in dollar */}
              <div className="w-64">
                <label className="label">Fr. cost ($)</label>
                {!dollarRate ? (
                  <input
                    type="number"
                    placeholder="Type here"
                    className={`${generalInput}`}
                    value={frCost}
                    onChange={handleFrCostChange}
                    readOnly
                  />
                ) : (
                  <input
                    type="number"
                    placeholder="Type here"
                    className={`${generalInput}`}
                    value={frCost}
                    onChange={handleFrCostChange}
                  />
                )}
              </div>

              {/* Paid date */}
              <div className="w-64">
                <label className="label">Paid Date</label>
                <input
                  type="text"
                  placeholder="Type here"
                  className={`${generalInput}`}
                  value={paidDate}
                  onChange={(e) => setPaidDate(e.target.value)}
                />
              </div>

              {/* Actual Paid*/}
              <div className="w-64">
                <label className="label">Actual Paid</label>
                <input
                  type="text"
                  placeholder="Type here"
                  className={`${generalInput}`}
                  value={actualPaid}
                  onChange={(e) => setActualPaid(e.target.value)}
                />
              </div>
              <div>
                <label className="label w-max">
                  <input
                    type="checkbox"
                    className="checkbox checkbox-sm md:checkbox-md"
                    onChange={handleCheckboxChangeCod}
                  />
                  <span className="label-text ml-2">Standardized COD</span>
                </label>
              </div>
            </div>

            {/* Summary Card */}
            <div className="m-auto card w-72 shadow-lg px-3">
              <div className="card-body flex flex-col gap-3">
                <p className="card-title">Summary</p>
                {summary.map((el, i) => {
                  return (
                    <p key={i}>
                      <strong>{el.title}:</strong> {el.value}
                    </p>
                  );
                })}

                <button className={`${positiveButton} m-auto`}>Add</button>
              </div>
            </div>
          </form>
    </>
  );
};

export default MCAddData;
