import axios from 'axios'
import React, { useEffect, useState } from 'react'
import {
  allFirstDiv,
  allSecondDiv,
  positiveButton,
  generalInput,
} from "../../components/DesignStandardize";
import { toast } from 'react-toastify';
const EditLocation = ({locationID}) => {
  console.log(locationID);
  const [data,setData] = useState({
    locationName:"",
    val1:0,
    val2:0,
    val3:0
  })
  const {locationName,
    val1,
    val2,
    val3} = data
  const fetchSingleLocation = async()=>{
    try {
      const response = await axios.get(`http://localhost:4000/maintable/fetchSingleLocation/${locationID}`);
      setData(response.data);
    } catch (error) {
      console.log("An error occured");
    }
  }
  useEffect(()=>{
    fetchSingleLocation();
  },[locationID])
  const updateLocation = async(e)=>{
    e.preventDefault();
    try {
      const response = await axios.put(`http://localhost:4000/maintable/updateSingleLocation/${locationID}`,data);
      console.log(response.data);
      toast.success("Successful Updation!")
    } catch (error) {
      console.log("An error occured");
    }
  }
  return (
    <div>
       <form
               className="flex flex-col gap-6 items-center"
               onSubmit={updateLocation}
            >
              
                <div className="flex flex-col gap-6">
                  {/* Location name */}
                  <div className="w-64">
                    <label className="label">
                      <span className="label-text">Location Name</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Location name"
                      className={`${generalInput}`}
                      required
                      value={locationName}
                      onChange={(e) => {
                       setData({...data,locationName:e.target.value.toUpperCase()})
                      }}
                    />
                  </div>

                  {/* Rate */}
                  {/* <div className="w-64">
                    <label className="label">
                      <span className="label-text">Rate</span>
                    </label>
                    <input
                      type="number"
                      placeholder="Rate"
                      className={`${generalInput}`}
                      required
                      value={val1}
                      onChange={(e) => {
                       setData({...data,val1:e.target.valu})
                      }}
                    />
                  </div> */}
             

                <div className="flex flex-col gap-6">
                  <div className="w-64">
                    <label className="label">
                      <span className="label-text">
                        Rate (less than 1kg($))
                      </span>
                    </label>
                    <input
                      type="number"
                      placeholder="Type here"
                      className={`${generalInput}`}
                      required
                      value={val1}
                      onChange={(e) => {
                        setData({...data,val1:e.target.value})
                      }}
                    />
                  </div>

                  <div className="w-64">
                    <label className="label">
                      <span className="label-text">
                        Rate (less than 50kg($))
                      </span>
                    </label>
                    <input
                      type="number"
                      placeholder="Type here"
                      className={`${generalInput}`}
                      value={val2}
                      onChange={(e) => {
                        setData({...data,val2:e.target.value});
                      }}
                      required
                    />
                  </div>

                  <div className="w-64">
                    <label className="label">
                      <span className="label-text">
                        Rate (more than 50kg($))
                      </span>
                    </label>
                    <input
                      type="number"
                      placeholder="Type here"
                      className={`${generalInput}`}
                      value={val3}
                      onChange={(e) => {
                        setData({...data,val3:e.target.value})
                      }}
                      required
                    />
                  </div>
                </div>
                <button className={`${positiveButton} m-auto`}>Submit</button>
              </div>
             
            </form>
    </div>
  )
}

export default EditLocation