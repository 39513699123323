import React, { useEffect, useState } from "react";
import LeftPanel from "../components/LeftPanel";
import TopNavbar from "../components/TopNavbar";
import * as xlsx from "xlsx";
import crypto from "crypto-browserify";
import axios from "axios";

import {
  allFirstDiv,
  allSecondDiv,
  blackButton,
  monthNumberToAbbreviation,
  positiveButton,
} from "../components/DesignStandardize";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
const Upload = () => {
  const [filedata, setFileData] = useState([]);
  const [loading, setLoading] = useState(false);
  const filterDuplicates = (nestedArray) => {
    const uniqueNames = [];
    const seenNames = {};

    return nestedArray.filter((item) => {
      if (
        item &&
        (item.COMPANY || item["COMPANY NAME"] || item[" COMPANY NAME "]) &&
        (!seenNames[item.COMPANY] ||
          !seenNames[item["COMPANY NAME"]] ||
          !seenNames[item[" COMPANY NAME "]])
      ) {
        seenNames[item.COMPANY] = true;
        uniqueNames.push(item.COMPANY);
        return true;
      }
      return false;
    });
  };
  const uniqueCustomer = filterDuplicates(filedata);
  console.log(uniqueCustomer);

  const customerReg = async () => {
    for (const customer of uniqueCustomer) {
      const data = {
        COMPANY: (
          customer.COMPANY ||
          customer["COMPANY NAME"] ||
          customer[" COMPANY NAME "]
        )
          ?.toString()
          .trim(),
        EncryptedID: crypto
          .createHash("sha256")
          .update(
            customer.COMPANY ||
              customer["COMPANY NAME"] ||
              customer[" COMPANY NAME "]
          )
          .digest("hex"),
        TypeOfPayment: customer["TYPE OF PAYMENT"],
        DeliveryItem: customer["DELIVERY ITEM"],
      };

      console.log("data", data);
      try {
        const response = await axios.post(
          "http://localhost:4000/customReg/mCustomerReg",
          data
        );
        console.log(response.data);

        await setDiffRateID(customer);
        // setDiffRate(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };
  async function setDiffRateID(customer) {
    // Define Express.js API endpoint
    const custID = await getCustomerID(
      customer.COMPANY || customer["COMPANY NAME"] || customer[" COMPANY NAME "]
    );
    // const differentRate = await diffRateID(custID);
    // console.log(differentRate);
    const data = {
      customerID: custID,
    };
    // console.log(hash);
    try {
      const response = await axios.post(
        "http://localhost:4000/prepaid/diffID",
        data
      );
      console.log("diffrate:", response.data);
      // setDiffRate(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  }
  const readexcel = async (e) => {
    setLoading(true);
    const selectedFile = e.target.files[0];
    const data = await selectedFile.arrayBuffer(selectedFile);
    console.log(selectedFile);
    console.log(data);
    const excelFile = xlsx.read(data);
    console.log(excelFile);
    const excelSheet = excelFile.Sheets[excelFile.SheetNames[0]];
    const excelJson = xlsx.utils.sheet_to_json(excelSheet);
    console.log(excelJson);
    setFileData(excelJson);
    setLoading(false);
  };
  async function getDiffrateID(custID) {
    // if(custID=== undefined){return null}

    try {
      const response = await axios.get(
        `http://localhost:4000/getDiffRateID/getDiffRateID?customerID=${custID}`
      );
      console.log("DiffrateID: ", response.data.DiffRateID);
      return response.data.DiffRateID;
    } catch (error) {
      console.log("An error occured");
    }
  }
  async function getCustomerID(username) {
    if (username === undefined) {
      return null;
    }
    const hash = crypto.createHash("sha256").update(username).digest("hex");
    try {
      const response = await axios.get(
        `http://localhost:4000/prepaid/getCustomerID?EncryptedID=${hash}`
      );
      console.log("custID inside:", response.data.customerID);
      return response.data.customerID;

      // console.log("custID:", custID);
      // setCustID(response.data.customerID);
      // setCustID(response.data.customerID);
      //  console.log(setCustID(response.customerID));
      //  console.log(custID);
    } catch (err) {
      console.error(err);
    }
  }
  const DateCheck = () => {
    for (const row of filedata) {
      const formattedDate = row.DATE;
      // ? row.DATE
      //
      // format(new Date(row.DATE), "dd.MM.yy")
      // : null;
      console.log("formatted date", formattedDate);
      // 10-Nov-2023 to Nov conversion
      // const dateString = "02.08.23";

      const [day, monthNumber, year] = formattedDate.split(".");
      const month = monthNumberToAbbreviation[monthNumber];
      console.log("Day:", day);
      console.log("Month:", month);
      console.log("Year:", year);

      // const month = row.DATE
      //   ? format(new Date(row.DATE), "MMM")
      //   : null;
      // console.log(month);
      const data = {
        Date: formattedDate,
        Month: month,
      };
      // console.log(data);
    }
  };
  // const date = DateCheck();
  // console.log(date);
  const uploadMainTable = async () => {
    try {
      for (const row of filedata) {
        const custID = await getCustomerID(row.COMPANY || row["COMPANY NAME"]);
        // const differentRate = await diffRateID(custID);
        // console.log(differentRate);
        // const DiffRateID = await getDiffrateID(custID);

        if (custID !== undefined) {
          console.log(
            "custID is undefined for ",
            row.COMPANY || row["COMPANY NAME"] || row[" COMPANY NAME "]
          );
        }
        const DiffRateID = await getDiffrateID(custID);
        console.log("custID in uploadMainTable:", custID);
        //
        const formattedDate = row.DATE;
        const [day, monthNumber, year] = formattedDate?.split(".") || [];
        const currentYear = new Date().getFullYear();
        const currentCentury = Math.floor(currentYear / 100) * 100;

        // Convert two-digit year to four-digit year
        const fourDigitYear = currentCentury + parseInt(year);

        console.log(fourDigitYear);
        const month = monthNumberToAbbreviation[monthNumber];
        const data = {
          CustomerID: custID,
          NO: row.NO,
          DiffRateID: DiffRateID,
          NO: row.NO,
          DATE: row.DATE?.trim(),
          MONTH: month,
          YEAR: fourDigitYear.toString(),
          FLIGHT: row.FLIGHT,
          MAWB: row.MAWB,
          AWB: row.AWB,
          SHIPPER: row.SHIPPER,
          COMPANY:
            (row.COMPANY || row["COMPANY NAME"] || row[" COMPANY NAME "])
              ?.toString()
              .trim() || null,
          PERSON: row["CONTACT PERSON"]?.toString().trim(),
          NUMBER: row["CONTACT NUMBER "]?.toString().trim(),
          AREA: row.AREA?.trim(),
          NOP: row.NOP,
          WGT: row.WGT,
          SPXTYPE: (row["SPX TYPE"] || row[" SPX TYPE"])?.trim(),
          PAYMENTMETH: row["TYPE OF PAYMENT"]?.trim(),
          DELIVERYITEM: row["DELIVERY ITEM"]?.trim(),
          VOLUMEWEIGHT: row["VOLUME WEIGHT"]?.trim(),
          TIME: row["TIME"]?.trim(),
          RECEIVING: row["RECEIVING PERSON"]?.trim(),
          REMARKS: row["REMARKS"]?.trim(),
          ROUNDWGT: row[" ROUND WEIGHT "],
          FrCost$: row[" Fr. COST($) "],
          FrCostBDT: row[" Fr. COST(TK) "],
          CUSTOM: row[" CUSTOM "],
          TOTAL: row[" TOTAL "],
          PAIDDATE: row["PAID DATE"]?.trim(),
          ACTUALPAID: row["ACTUAL PAID"]?.toString().trim(),
        };
        console.log("data Table:", data);
        try {
          const response = await axios.post(
            "http://localhost:4000/table/tableinsertion",
            data
          );
          // console.log("table response data", response.data);
          // toast.success("All the Data has been Inserted Successfully!")
        } catch (err) {
          console.error("error:", err);
          // toast.error("An error occured");
        }
        // const differentRate = await diffRateID(custID);
        // console.log(differentRate);
      }
      setLoading(false);
      toast.success("All the data has been inserted Successfully!");
    } catch (error) {
      toast.error("An error occured");
    }
  };

  //MAWB	AWB	SHIPPER	COMPANY	CONTACT PERSON	CONTACT NUMBER 	AREA	NOP	WGT	 SPX TYPE	TYPE OF PAYMENT	DELIVERY ITEM	VOLUME WEIGHT	DATE OF DELIVERY	TIME	RECEIVING PERSON		REMARKS	 ROUND WEIGHT 	 Fr. COST($) 	 Fr. COST(TK) 	 CUSTOM 	 TOTAL 	PAID DATE	ACTUAL PAID

  // Customer registration panel
  const oneClickForAllMethods = async () => {
    setLoading(true);
    await customerReg();
    // await diffRateID();
    await uploadMainTable();
    setLoading(false);
  };
  return (
    <>
      <LeftPanel />
      <TopNavbar />

      <div className={`${allFirstDiv}`}>
        <div className={`${allSecondDiv}`}>
          <div className="flex flex-col gap-6">
            <input
              type="file"
              class="file-input file-input-bordered w-full max-w-xs"
              onChange={(e) => {
                readexcel(e);
              }}
            />
            <button
              onClick={oneClickForAllMethods}
              className={`${positiveButton} mr-auto`}
            >
              Upload
            </button>
          </div>
          <div className="max-w-96 max-h-[500px] overflow-auto">
            <form>
              {loading ? (
                <p>
                  Please wait a moment.......
                  <ClipLoader
                    color="##ffffff"
                    loading={loading}
                    size={35}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </p>
              ) : (
                <table className="table text-center overflow-x-auto">
                  {/* head */}
                  <thead>
                    <tr className="sticky top-0 bg-white">
                      <th>Serial No.</th>
                      <th>NO.</th>
                      <th>Flight</th>
                      <th>Date</th>
                      <th>MAWB</th>
                      <th>AWB</th>
                      <th>Shipper</th>
                      <th>Company</th>
                      <th>Contact Person</th>
                      <th>Contact Number</th>
                      <th>Area</th>
                      <th>NOP</th>
                      <th>WGT</th>
                      <th>Spx Type</th>
                      <th>Type of Payment</th>
                      <th>Delivery Type</th>
                      <th>Volume of Weight</th>
                      <th>FrCost$ </th>
                      <th>FrCostTK. </th>
                      <th>Custom</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {uniqueCustomer.map((el, i) => {
                      return (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{el.NO}</td>
                          <td>{el.FLIGHT}</td>
                          <td>{el.DATE}</td>
                          <td>{el.MAWB}</td>
                          <td>{el.AWB}</td>
                          <td>{el.SHIPPER}</td>
                          <td>
                            {el.COMPANY ||
                              el["COMPANY NAME"] ||
                              el[" COMPANY NAME "]}
                          </td>
                          <td>{el["CONTACT PERSON"]}</td>
                          <td>{el["CONTACT NUMBER"]}</td>
                          <td>{el.AREA}</td>
                          <td>{el.NOP}</td>
                          <td>{el.WGT}</td>
                          <td>{el["SPX TYPE"]}</td>
                          <td>{el["TYPE OF PAYMENT"]}</td>
                          <td>{el["DELIVERY TYPE"]}</td>
                          <td>{el["VOLUME OF WEIGHT"]}</td>
                          <td> {el["Fr.COST($)"]} </td>
                          <td> {el["Fr. COST(TK)"]} </td>
                          <td>{el["CUSTOM"]}</td>
                          <td>{el["TOTAL"]}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </form>
          </div>
        </div>

        <div className="h-20" />
      </div>
    </>
  );
};

export default Upload;
