import React, { useEffect, useState } from "react";
import axios from "axios";
import { positiveButton } from "../../components/DesignStandardize";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useParams } from "react-router-dom";
import "jspdf-autotable";
import Logo from "../../img/Logo.png";
import PdfFooter from "../../components/PdfFooter";
const DownloadIndividual = () => {
  const id = useParams();
  // console.log("id:", id.MainTableID);
  const [record, setRecord] = useState("");

  async function getAllInfoMonth() {
    let response = "";
    try {
      response = await axios.get(
        `http://localhost:4000/maintable/getSingleRecord/${id.MainTableID}`
      );
      console.log("Selected Record", response.data);
      setRecord(response.data);
    } catch (err) {
      console.error(err);
      response = null;
    }
  }

  useEffect(() => {
    getAllInfoMonth();
  }, []);

  const handleDownloadPDF = () => {
    const pdfContent = document.getElementById("pdf-content");
    console.log(pdfContent);
    html2canvas(pdfContent, {
      scrollX: -window.scrollX,
      scrollY: -window.scrollY,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save(`Import_Bill_${record.COMPANY}.pdf`);
    });
  };

  return (
    <div className="flex flex-col gap-2 p-2 bg-slate-50">
      <button onClick={handleDownloadPDF} className={`${positiveButton}`}>
        Download PDF
      </button>

      <div
        id="pdf-content"
        className="flex flex-col gap-3 shadow-lg bg-white my-5"
      >
        <div className="flex h-20">
          <img className="w-32 m-3 absolute" src={Logo} alt="SCS Logo" />
          <p className="m-auto text-5xl italic font-serif text-[#8a7b22]">
            SCS Express International
          </p>
        </div>
        {/* Headline for Table */}
        <div className="uppercase text-center text-gray-900 font-semibold">
          import bill of {record.COMPANY}, {record.YEAR}
        </div>

        {/* Table for Specific Month */}
        <div className="max-w-96 overflow-auto border mx-3">
          <table className="table text-center overflow-x-auto">
            <thead>
              <tr className="bg-gray-200">
                <th>No.</th>
                <th>Date</th>
                <th>AWB</th>
                <th>COMPANY NAME</th>
                <th>WGT</th>
                <th>DELIVERY ITEM</th>
                <th>FRCOST$</th>
                <th>FRCOSTBDT</th>
                <th>CUSTOM</th>
                <th>TOTAL</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>{record.NO}</td>
                <td>{record.DATE}</td>
                <td>{record.AWB}</td>
                <td>
                  {record.COMPANY ||
                    record["COMPANY NAME"] ||
                    record[" COMPANY NAME "]}
                </td>
                <td>{record.WGT}</td>
                <td>{record["DELIVERY ITEM"]}</td>
                <td>{record.FRCOST$}</td>
                <td>{record.FRCOSTBDT}</td>
                <td>{record.CUSTOM}</td>
                <td>{record.TOTAL}</td>
              </tr>

              {/* To show the total amount */}
              <tr>
                <td colSpan="12" className="text-right">
                  Total Amount: <strong>{record.TOTAL}</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Footer */}
        <PdfFooter />
      </div>
    </div>
  );
};

export default DownloadIndividual;
