import React, { useEffect, useState } from "react";
import {
  generalInput,
  positiveButton,
} from "../../components/DesignStandardize";
import axios from "axios";
import { toast } from "react-toastify";
const EditCustomer = ({ MainTableID }) => {
  const [data, setData] = useState({
    // initialData
    NO: "",
    FLIGHT: "",
    MAWB: "",
    AWB: "",
    SHIPPER: "",
    COMPANY: "",
    "CONTACT PERSON": "",
    "CONTACT NUMBER": "",
    AREA: "",
    NOP: 0,
    WEIGHT: 0,
    SPXTYPE: "",
    "TYPE OF PAYMENT": "",
    "DELIVERY ITEM": "",
    "VOLUME WEIGHT": "",
    TIME: "",
    "RECEIVING PERSON": "",
    REMARKS: "",
    "ROUND WEIGHT": 0,
    FRCOST$: 0,
    FRCOSTBDT: 0,
    "PAID DATE": "",
    "ACTUAL DATE": "",
    CUSTOM: 0,
    TOTAL: 0,
  });
  console.log(data);
  const {
    NO,
    FLIGHT,
    MAWB,
    AWB,
    SHIPPER,
    COMPANY,
    "CONTACT PERSON": PERSON,
    "CONTACT NUMBER": NUMBER,
    AREA,
    NOP,
    WEIGHT,
    "SPX TYPE": SPXTYPE,
    "TYPE OF PAYMENT": PAYMENTMETHOD,
    "DELIVERY ITEM": DELIVERYITEM,
    "VOLUME WEIGHT": VOLUMEWEIGHT,
    TIME,
    "RECEIVING PERSON": RECEIVING,
    REMARKS,
    "ROUND WEIGHT": ROUNDWEIGHT,
    FRCOST$,
    FRCOSTBDT,
    "PAID DATE": PAIDDATE,
    "ACTUAL DATE": ACTUALPAID,
    CUSTOM,
    TOTAL,
  } = data;
  // console.log(data);
  const getCustomer = async (id) => {
    try {
      const response = await axios.get(
        `http://localhost:4000/maintable/getSingleCustomer/${id}`
      );
      setData(response.data); // Assuming the response is an object containing the customer data
    } catch (error) {
      console.log("Error fetching customer:", error);
    }
  };
  useEffect(() => {
    getCustomer(MainTableID);
  }, [MainTableID]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      e.preventDefault();
      console.log("Data before sending:", data);
      const res = await axios.put(
        `http://localhost:4000/maintable/updateTable/${MainTableID}`,
        data
      );
      toast.success("Customer Updated Successfully!");
      window.location.reload();
    } catch (error) {
      toast.error("Customer Update Failed!!!");
    }
  };

  return (
    <div>
      <form className="flex flex-col gap-5" onSubmit={handleSubmit}>
        {/* Input fields */}
        <div className="flex flex-col gap-3 items-center">
          <div className="w-64">
            <label className="label">NO.</label>
            <input
              type="text"
              placeholder="Type here"
              className={`${generalInput}`}
              value={NO}
              id="no"
              onChange={(e) => setData({ ...data, NO: e.target.value })}
            />
          </div>

          {/* Flight */}
          <div className="w-64">
            <label className="label">Flight</label>
            <input
              type="text"
              placeholder="Type here"
              className={`${generalInput}`}
              value={FLIGHT}
              onChange={(e) => setData({ ...data, FLIGHT: e.target.value })}
            />
          </div>

          {/* MAWB */}
          <div className="w-64">
            <label className="label">MAWB</label>
            <input
              type="text"
              placeholder="Type here"
              className={`${generalInput}`}
              value={MAWB}
              onChange={(e) => setData({ ...data, MAWB: e.target.value })}
            />
          </div>

          {/* AWB */}
          <div className="w-64">
            <label className="label">AWB</label>
            <input
              type="text"
              placeholder="Type here"
              className={`${generalInput}`}
              value={AWB}
              onChange={(e) => setData({ ...data, AWB: e.target.value })}
            />
          </div>

          {/* Shipper */}
          <div className="w-64">
            <label className="label">Shipper</label>
            <input
              type="text"
              placeholder="Type here"
              className={`${generalInput}`}
              value={SHIPPER}
              onChange={(e) => setData({ ...data, SHIPPER: e.target.value })}
            />
          </div>

          {/* Username */}
          <div className="w-64">
            <label className="label">Company Name</label>
            <input
              type="text"
              placeholder="Type here"
              className={`${generalInput}`}
              value={COMPANY}
              onChange={(e) => setData({ ...data, COMPANY: e.target.value })}
            />
          </div>

          {/* Person */}
          <div className="w-64">
            <label className="label">Person</label>
            <input
              type="text"
              placeholder="Type here"
              className={`${generalInput}`}
              value={PERSON}
              onChange={(e) =>
                setData((prevData) => ({
                  ...prevData,
                  "CONTACT PERSON": e.target.value,
                }))
              }
            />
          </div>

          {/* Number */}
          <div className="w-64">
            <label className="label">Number</label>
            <input
              type="text"
              placeholder="Type here"
              className={`${generalInput}`}
              value={NUMBER}
              onChange={(e) =>
                setData({ ...data, "CONTACT NUMBER": e.target.value })
              }
            />
          </div>

          {/* Area */}
          <div className="w-64">
            <label className="label">Area</label>
            <input
              type="text"
              placeholder="Type here"
              className={`${generalInput}`}
              value={AREA}
              onChange={(e) => setData({ ...data, AREA: e.target.value })}
            />
          </div>

          {/* NOP */}
          <div className="w-64">
            <label className="label">NOP</label>
            <input
              type="number"
              placeholder="Type here"
              className={`${generalInput}`}
              value={NOP}
              onChange={(e) => setData({ ...data, NOP: e.target.value })}
            />
          </div>

          {/* Weight */}
          <div className="w-64">
            <label className="label">Weight</label>
            <input
              type="number"
              placeholder="Type here"
              className={`${generalInput}`}
              value={WEIGHT}
              onChange={(e) => setData({ ...data, WEIGHT: e.target.value })}
            />
          </div>

          {/* SPX type */}
          <div className="w-64">
            <label className="label">SPX type</label>
            <input
              type="text"
              placeholder="Type here"
              className={`${generalInput}`}
              value={SPXTYPE}
              onChange={(e) => setData({ ...data, "SPX TYPE": e.target.value })}
            />
          </div>

          {/* Payment method */}
          <div className="w-64">
            <label className="label">Payment method</label>
            <input
              type="text"
              placeholder="Type here"
              className={`${generalInput}`}
              value={PAYMENTMETHOD}
              onChange={(e) =>
                setData({ ...data, "TYPE OF PAYMENT": e.target.value })
              }
            />
          </div>

          {/* Delivery Item */}
          <div className="w-64">
            <label className="label">Delivery Item</label>
            <input
              type="text"
              placeholder="Type here"
              className={`${generalInput}`}
              value={DELIVERYITEM}
              onChange={(e) =>
                setData({ ...data, "DELIVERY ITEM": e.target.value })
              }
            />
          </div>

          {/* Volume weight */}
          <div className="w-64">
            <label className="label">Volume Weight</label>
            <input
              type="text"
              placeholder="Type here"
              className={`${generalInput}`}
              value={VOLUMEWEIGHT}
              onChange={(e) =>
                setData({ ...data, "VOLUME WEIGHT": e.target.value })
              }
            />
          </div>

          {/* Time */}
          <div className="w-64">
            <label className="label">Time</label>
            <input
              type="text"
              placeholder="Type here"
              className={`${generalInput}`}
              value={TIME}
              onChange={(e) => setData({ ...data, TIME: e.target.value })}
            />
          </div>

          {/* Receiving */}
          <div className="w-64">
            <label className="label">Receiving</label>
            <input
              type="text"
              placeholder="Type here"
              className={`${generalInput}`}
              value={RECEIVING}
              onChange={(e) =>
                setData({ ...data, "RECEIVING PERSON": e.target.value })
              }
            />
          </div>

          {/* Remarks */}
          <div className="w-64">
            <label className="label">Remarks</label>
            <input
              type="text"
              placeholder="Type here"
              className={`${generalInput}`}
              value={REMARKS}
              onChange={(e) => setData({ ...data, REMARKS: e.target.value })}
            />
          </div>
          {/* Round Weight */}
          <div className="w-64">
            <label className="label">roundWeight</label>
            <input
              type="number"
              placeholder="Type here"
              className={`${generalInput}`}
              value={ROUNDWEIGHT}
              onChange={(e) =>
                setData({ ...data, "ROUND WEIGHT": e.target.value })
              }
            />
          </div>

          {/* Fr cost in dollar */}
          <div className="w-64">
            <label className="label">Fr. cost ($)</label>
            <input
              type="number"
              placeholder="Type here"
              className={`${generalInput}`}
              value={FRCOST$}
              onChange={(e) => setData({ ...data, FRCOST$: e.target.value })}
            />
          </div>
          {/* frcost in bdt */}
          <div className="w-64">
            <label className="label">Fr. cost ($)</label>
            <input
              type="number"
              placeholder="Type here"
              className={`${generalInput}`}
              value={FRCOSTBDT}
              onChange={(e) => setData({ ...data, FRCOSTBDT: e.target.value })}
            />
          </div>
          {/* Paid date */}
          <div className="w-64">
            <label className="label">Paid Date</label>
            <input
              type="text"
              placeholder="Type here"
              className={`${generalInput}`}
              value={PAIDDATE}
              onChange={(e) =>
                setData({ ...data, "PAID DATE": e.target.value })
              }
            />
          </div>

          {/* Actual Paid*/}
          <div className="w-64">
            <label className="label">Actual Paid</label>
            <input
              type="text"
              placeholder="Type here"
              className={`${generalInput}`}
              value={ACTUALPAID}
              onChange={(e) =>
                setData({ ...data, "ACTUAL DATE": e.target.value })
              }
            />
          </div>
          {/* Custom */}
          <div className="w-64">
            <label className="label">Custom</label>
            <input
              type="number"
              placeholder="Type here"
              className={`${generalInput}`}
              value={CUSTOM}
              onChange={(e) => setData({ ...data, CUSTOM: e.target.value })}
            />
          </div>
          {/* TOTAl */}
          <div className="w-64">
            <label className="label">Actual Paid</label>
            <input
              type="number"
              placeholder="Type here"
              className={`${generalInput}`}
              value={TOTAL}
              onChange={(e) => setData({ ...data, TOTAL: e.target.value })}
            />
          </div>
          <button className={`${positiveButton} m-auto`}>Submit</button>
        </div>
      </form>
    </div>
  );
};

export default EditCustomer;
