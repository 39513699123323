import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  generalRateInput,
  positiveButton,
} from "../../components/DesignStandardize";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useParams } from "react-router-dom";
import "jspdf-autotable";
import Logo from "../../img/Logo.png";
import DownloadIcon from "@mui/icons-material/FileDownloadOutlined";
import PdfFooter from "../../components/PdfFooter";

const DownloadFull = () => {
  const Month = useParams();
  const [arr, setArr] = useState([]);
  const [AWB, SetAWB] = useState("");
  const [selectedPaymentOb, setSelctedPaymentOb] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedShortMonth, setSelectedShortMonth] = useState("");
  const [Area, setArea] = useState("");
  const [Delivery, setDelivery] = useState("");
  const [search, setSearch] = useState(0);
  const [companyName, setCompanyName] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [finalTable, setFinalTable] = useState([]);
  const [startIndex, setStartIndex] = useState(1);
  const [endIndex, setEndIndex] = useState(1);
  const [above30, setAbove30] = useState(false);
  const recordsPerPage = 50;

  async function getAllInfoMonth() {
    let response = "";
    try {
      response = await axios.get(
        `http://localhost:4000/maintable/getAllInfoMonth?Month=${Month.MONTH}&Year=${Month.YEAR}`
      );
      setArr(response.data.rows);
    } catch (err) {
      console.error(err);
      response = null;
      if (selectedMonth) {
        // toast.error("No data recorded this month");
      }
    }
  }
  // Calculate the total number of pages
  const totalPages = Math.ceil(finalTable.length / recordsPerPage);
  // console.log(totalPages);

  useEffect(() => {
    getAllInfoMonth();
  }, []);

  const handleDownloadPDF = () => {
    const pdfContent = document.getElementById("pdf-content");
    console.log(pdfContent);
    html2canvas(pdfContent, {
      scrollX: -window.scrollX,
      scrollY: -window.scrollY,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save(`Import_Bill_${Month.MONTH}.pdf`);
    });
  };

  const filterData = async () => {
    const filteredData = await arr.filter((data) => {
      const areaCondition =
        Area !== "" ? data.AREA === Area.toUpperCase() : true;
      const AirwayBill = AWB !== "" ? data.AWB === AWB : true;
      const Month =
        selectedMonth !== "" ? data.MONTH === selectedShortMonth : true;
      const paymentCondition =
        selectedPaymentOb !== ""
          ? data["TYPE OF PAYMENT"] === selectedPaymentOb
          : true;
      const deliveryCondition =
        Delivery !== "" ? data["DELIVERY ITEM"] === Delivery : true;
      // console.log("Data Month:", data.MONTH);
      // console.log("Selected Month:", selectedShortMonth);
      // console.log("Month Condition:", Month);
      // console.log("Selected Payment:", selectedPaymentOb);
      // console.log("Payment COndition", paymentCondition);
      setCompanyName(data.COMPANY);
      return (
        Month &&
        areaCondition &&
        AirwayBill &&
        paymentCondition &&
        deliveryCondition
      );
    });
    // console.log(filteredData);
    setFilteredData(filteredData);
    setSearch(1);
  };
  const currentPageRecords = finalTable.slice(
    (startIndex - 1) * recordsPerPage,
    startIndex * recordsPerPage
  );

  // Calculate total
  const calculateTotal = () => {
    let total = 0;
    filteredData.slice(startIndex - 1, endIndex).forEach((el) => {
      if (el.TOTAL === null) el.TOTAL = 0;
      total += parseFloat(el.TOTAL);
    });
    return total;
  };
  const currentPageTotal = calculateTotal();

  useEffect(() => {
    if (search === 0) {
      setFinalTable(arr);
    } else {
      setFinalTable(filteredData);
    }
  }, [search, arr, filteredData]);

  // Call the filterData function whenever the 'Area' state changes
  useEffect(() => {
    filterData();
  }, [Area, arr]);
  // console.log(arr);
  useEffect(() => {
    const difference = Math.abs(endIndex - startIndex + 1);
    if (difference > 30) {
      setAbove30(true);
    } else {
      setAbove30(false);
    }
  }, [startIndex, endIndex]);

  return (
    <div className="flex flex-col gap-2 p-2 bg-slate-50">
      {/* Input fields */}
      <div className="flex flex-row gap-2">
        {/* Input point from */}
        <div className="text-black flex gap-2 items-center">
          <span>Start Index:</span>
          <input
            type="number"
            placeholder="Start"
            value={startIndex}
            onChange={(e) => setStartIndex(e.target.value)}
            className={`${generalRateInput}`}
          />
        </div>
        {/* Input point to */}
        <div className="text-black flex gap-2 items-center">
          <span>End Index:</span>
          <input
            type="number"
            placeholder="End"
            value={endIndex}
            onChange={(e) => setEndIndex(e.target.value)}
            className={`${generalRateInput}`}
          />
        </div>
        {!above30 && (
          <button onClick={handleDownloadPDF} className={`${positiveButton}`}>
            Download PDF <DownloadIcon fontSize="small" />
          </button>
        )}
      </div>

      <p className="text-sm text-slate-500">
        *The difference between start index and end index must be below or equal
        30
      </p>

      <div
        id="pdf-content"
        className="flex flex-col gap-3 shadow-lg bg-white my-5"
      >
        <div className="flex h-20">
          <img className="w-32 m-3 absolute" src={Logo} alt="SCS Logo" />
          <p className="m-auto text-5xl italic font-serif text-[#8a7b22]">
            SCS Express International
          </p>
        </div>
        {/* Headline for Table */}
        <div className="uppercase text-center text-gray-900 font-semibold">
          import bill of {Month.MONTH}, {Month.YEAR}
        </div>

        {/* Table for Specific Month */}
        <div className="max-w-96 overflow-auto border mx-3">
          <table className="table text-center overflow-x-auto">
            <thead>
              <tr className="sticky top-0 bg-gray-200">
                <th>No.</th>
                <th>Date</th>
                <th>AWB</th>
                <th>COMPANY NAME</th>
                <th>WGT</th>
                <th>DELIVERY ITEM</th>
                <th>FRCOST$</th>
                <th>FRCOSTBDT</th>
                <th>CUSTOM</th>
                <th>TOTAL</th>
              </tr>
            </thead>

            <tbody>
              {currentPageRecords.map((el, i) => {
                return (
                  <>
                    {i >= startIndex - 1 && i < endIndex && (
                      <tr key={i}>
                        <td className="sticky left-0 z-10 bg-white">{i + 1}</td>
                        <td>{el.DATE}</td>
                        <td>{el.AWB}</td>
                        <td>
                          {el.COMPANY ||
                            el["COMPANY NAME"] ||
                            el[" COMPANY NAME "]}
                        </td>
                        <td>{el.WGT}</td>
                        <td>{el["DELIVERY ITEM"]}</td>
                        <td>{el.FRCOST$}</td>
                        <td>{el.FRCOSTBDT}</td>
                        <td>{el.CUSTOM}</td>
                        <td>{el.TOTAL}</td>
                      </tr>
                    )}
                  </>
                );
              })}

              {/* To show the total amount */}
              {currentPageRecords.length > 0 && (
                <tr>
                  <td colSpan="12" className="text-right">
                    Total Amount: <strong>{currentPageTotal}</strong>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Footer */}
        <PdfFooter />
      </div>
    </div>
  );
};

export default DownloadFull;
