import React, { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import CustomerDetails from "../../components/CustomerDetails";
import { blackLinkButton } from "../../components/DesignStandardize";

const MCTable = () => {
  const [customer, setCustomer] = useState([]);
  const [diffRate, setDiffRate] = useState([]);
  const [diffRates, setDiffRates] = useState([]);
  const [selectCustomerID, setSelectedCustomerID] = useState(null);
  const [selectEncryptID, setselectEncryptID] = useState(null);
  const fetchData = async () => {
    await fetch("http://localhost:4000/customer/getCustomer", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "customer data:");
        setCustomer(data.data);
      });
  };
  const fetchDiffrates = async () => {
    await fetch("http://localhost:4000/prepaid/diffrates", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "diffrates data:");
        setDiffRates(data.data);
      });
  };
  const fetchDiffRateID = async () => {
    try {
      await fetch("http://localhost:4000/getDiffRateID/getDiffRates", {
        method: "GET",
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data, "Diffrate data:");
          setDiffRate(data.data);
        });
    } catch (error) {
      console.log("An error occured");
    }
  };
  const handleDeleteDiffrate = async (id, name) => {
    alert(`Are u sure u want to delete ${name}?`);
    try {
      const response = await fetch(
        `http://localhost:4000/prepaid/delete/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log(data.message);
      } else {
        console.error("Error deleting data:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };
  const handleDeleteCustomer = async (id, name) => {
    // alert(`Are u sure u want to delete ${name}?`)
    try {
      const response = await fetch(
        `http://localhost:4000/customer/remove/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log(data.message);
      } else {
        console.error("Error deleting data:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };
  const handleDeleteMaintablerow = async (id) => {
    // alert(`Are u sure u want to delete ${name}?`)
    try {
      const response = await fetch(
        `http://localhost:4000/maintable/deleteTable/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log(data.message);
      } else {
        console.error("Error deleting data:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };
  const handleDelete = async (id, name) => {
    await handleDeleteDiffrate(id, name);
    await handleDeleteCustomer(id);
    await handleDeleteMaintablerow(id);
    window.location.reload();
  };
  useEffect(() => {
    fetchData();
    fetchDiffrates();
    fetchDiffRateID();
  }, []);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (event.target.id === "my_modal_5") {
        document.getElementById("my_modal_5").close();
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);
  const closeModal = () => {
    document.getElementById("my_modal_5").close();
    // fetchData();
  };
  return (
    <>
      <div className="max-w-96 overflow-auto">
        <table className="table text-center overflow-x-auto">
          <thead>
            <tr className="sticky top-0 bg-white">
              <th>Name</th>
              <th>Remove</th>
              <th>Add rate</th>
            </tr>
          </thead>
          <tbody>
            {customer.map((item, i) => {
              const correspondingDiffRate = diffRates.find(
                (rate) => rate.CustomerID === item.CustomerID
              );
              return (
                <tr key={i}>
                  <td className="font-bold">
                    <Link to={`/update/${item.CustomerID}/${item.EncryptedID}`}>
                      {item.Name}
                    </Link>
                  </td>

                  <td className="text-[#DB1E1E]">
                    <button
                      onClick={() => {
                        handleDelete(item.CustomerID, item.Name);
                      }}
                    >
                      <DeleteIcon />
                    </button>
                    <p className="hover:underline">Remove</p>
                  </td>

                  {/* Ad different button */}
                  {correspondingDiffRate &&
                  (correspondingDiffRate.tillW1 !== null ||
                    correspondingDiffRate.tillW2 !== null ||
                    correspondingDiffRate.tillW3 !== null ||
                    correspondingDiffRate.tillW4 !== null ||
                    correspondingDiffRate.tillW5 !== null ||
                    correspondingDiffRate.val1 !== null ||
                    correspondingDiffRate.val2 !== null ||
                    correspondingDiffRate.val3 !== null ||
                    correspondingDiffRate.val4 !== null ||
                    correspondingDiffRate.val5 !== null) ? (
                    <></>
                  ) : (
                    <td>
                      <button
                        className={`${blackLinkButton}`}
                        onClick={() => {
                          setSelectedCustomerID(item.CustomerID);
                          setselectEncryptID(item.EncryptedID);
                          document.getElementById("my_modal_5").showModal();
                        }}
                      >
                        Add Different Rate
                      </button>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* Modal pop up to add different rate */}
      <dialog id="my_modal_5" className="modal m-auto w-11/12">
        <div className="modal-box flex flex-col gap-5">
          <div className="flex flex-col gap-3 text-lg">
            <p className="font-bold flex justify-between items-center">
              <p>Set rate for different range</p>
              <button
                className="w-max hover:tooltip hover:tooltip-bottom"
                data-tip="Close"
                onClick={closeModal}
              >
                <CloseIcon />
              </button>
            </p>
            <p className="text-sm text-[#9FA1A6]">
              Press ESC key or click close button to close
            </p>
          </div>
          <CustomerDetails
            customer={customer}
            CustomerID={selectCustomerID}
            EncryptedID={selectEncryptID}
          />
        </div>
      </dialog>
    </>
  );
};

export default MCTable;
