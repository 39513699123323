import React from "react";

const PdfFooter = () => {
  const specialComments = [
    { text: "Please do not pay without money receipt" },
    { text: "Payments of this invoice with in 4 days receive" },
    {
      text: "All cheques should be crossed and made payable to SCS Express International",
    },
  ];
  return (
    <div className="flex flex-col gap-6">
      <div className="font-semibold italic text-black">
        <p className="text-lg ml-3">SPECIAL COMMENTS:</p>
        {specialComments.map((el, i) => {
          return (
            <p key={i} className="ml-3">
              {i + 1}.{el.text}
            </p>
          );
        })}
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 italic bg-[#ff5555] gap-[2px] text-[#ff5555] font-serif justify-items-center items-center">
        <div className="bg-white w-full flex justify-center">
          Dhaka Office :<br />
          Block-J, Road-2, House-4,
          <br />
          Baridhara-R/A, Dhaka-1212
          <br />
          Tel: 9851366, 9859946-31-74,
          <br />
          Mob: 01762621146
        </div>

        <div className="bg-white h-full w-full flex items-center justify-center">
          Email: ro@scsexp.com
          <br />
          http: //www.scsexpress.com
        </div>

        <div className="bg-white w-full flex justify-center">
          Chittagong Office:
          <br />
          Yacub Ali Market, 50/B
          <br />
          C/A, Chittagong. <br />
          Tel.: 031-2512654
          <br />
          Mobile: 01819-317388
        </div>
      </div>
      <p className="text-center text-[#ff5555]">
        <span className="font-serif">Customer care:</span> 01760002842,
        01734751530, 01764258241
      </p>
    </div>
  );
};

export default PdfFooter;
