import React from "react";
import LeftPanel from "../components/LeftPanel";
import TopNavbar from "../components/TopNavbar";
import { allFirstDiv, allSecondDiv } from "../components/DesignStandardize";
import SeeTableSummary from "./components/SeeTableSummary";

const SeeTable = () => {
  return (
    <div>
      <LeftPanel />
      <TopNavbar />
      <div className={`${allFirstDiv}`}>
        <div className={`${allSecondDiv}`}>
          <SeeTableSummary />
        </div>
      </div>
      <div className="h-20" />
    </div>
  );
};
export default SeeTable;
