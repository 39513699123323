import React, { useEffect, useState } from "react";

const OverviewSection = () => {
  const [customer, setCustomer] = useState([]);
  const [users, setUsers] = useState([]);
  const fetchData = async () => {
    await fetch("http://localhost:4000/customer/getCustomer", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "customer data:");
        setCustomer(data.data);
      });
  };
  const fetchUser = async () => {
    await fetch("http://localhost:4000/fetch/getUsers", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "usersData");
        setUsers(data.data);
      });
  };
  useEffect(() => {
    fetchUser();
    fetchData();
  }, []);
  const overview = [
    { title: "Users", value: users.length },
    { title: "Customers", value: customer.length },
  ];
  return (
    <div className="flex flex-col gap-10">
      <p className="text-3xl font-bold">Overview</p>
      <div className="flex flex-row lg:justify-start justify-center flex-wrap gap-5">
        {overview.map((el, i) => {
          return (
            <p
              key={i}
              className="hover:shadow-lg bg-gray-100 p-10 text-center font-semibold rounded-md"
            >
              <p className="text-xl md:text-2xl">
                {el.title}{" "}
                <span className="text-lg md:text-xl">({el.value})</span>
              </p>
            </p>
          );
        })}
      </div>
    </div>
  );
};

export default OverviewSection;
