import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import RightArrowIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
// import { blackLinkButton } from "../../components/DesignStandardize";
const MCFirstSection = () => {
  const [customer, setCustomer] = useState([]);
  const fetchData = async () => {
    await fetch("http://localhost:4000/customer/getCustomer", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "customer data:");
        setCustomer(data.data);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <div className="flex flex-col gap-1 pb-8 border-b-2 border-gray-200 font-[Poppins]">
        <span className="text-3xl font-extrabold text-black">
          Monthly Customers
        </span>
        <p className="text-[#9FA1A6]">
          View or Remove your customers here. Press Ctrl+F to search customer.
        </p>
      </div>

      <div className="flex flex-col md:flex-row gap-3 justify-between">
        <div>
          <p className="text-[#0084FF] text-lg border-b-2 border-[#0084FF] px-2 w-max">
            Total Monthly customers: {customer.length}
          </p>
        </div>

        {/* To add new customer */}
        {/* <Link className={`${blackLinkButton}`} to="/addCustomer">
          Add Customers <RightArrowIcon />
        </Link> */}
      </div>
    </>
  );
};

export default MCFirstSection;
