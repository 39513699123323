import React, { useEffect, useState } from "react";
import LeftPanel from "../components/LeftPanel";
import TopNavbar from "../components/TopNavbar";
import crypto from "crypto-browserify";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import {
  allFirstDiv,
  allSecondDiv,
  positiveButton,
  generalInput,
} from "../components/DesignStandardize";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/EditNoteOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import EditLocation from "./components/EditLocation";
import { Link } from "react-router-dom";
const Location = () => {
  const [location, setLocation] = useState([]);
  const [locationname, setLocationname] = useState("");
  const [rate, setRate] = useState(0);
  const [weight1, setWeight1] = useState(0);
  const [weight2, setWeight2] = useState(0);
  const [weight3, setWeight3] = useState(0);
  const [toggleState, setToggleState] = useState(1);
  const [locationID,setLocationID] = useState(null);
  const [locationName,setLocationName] = useState("");
  const changeTab = (tabs) => {
    setToggleState(tabs);
  };
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (event.target.id === "my_modal_5") {
        document.getElementById("my_modal_5").close();
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const closeModal = () => {
    document.getElementById("my_modal_5").close();
  };
  // Function to fetch the location
  async function getLocation() {
    await fetch("http://localhost:4000/maintable/fetchLocations", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "Location data:");
        setLocation(data.data);
      });
  }
  useEffect(() => {
    getLocation();
  }, []);

  // Function to add a new location
  async function RegLocation() {
    const encryptedID = crypto
      .createHash("sha256")
      .update(locationname.toUpperCase())
      .digest("hex");
    const locationExists = location.some(
      (loc) => loc.locationName.toUpperCase() === locationname.toUpperCase()
    );
    if (locationExists) {
      toast.error("Location already exists");
      return;
    }
    try {
      const apiURL = `http://localhost:4000/register/location`;
      await fetch(apiURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          locationName: locationname.toUpperCase(),
          EncryptedID: encryptedID,
          val1: weight1 * rate,
          val2: weight2 * rate,
          val3: weight3 * rate,
        }),
      });
      toast.success("Location Insertion Done Successfully");
    } catch (err) {
      console.error("Error:", err);
    }
  }

  // Function to delete a location
  const handleDelete = async (id, name) => {
    const userConfirmation = window.confirm(
      `Are you sure you want to delete ${name}?`
    );
    if (userConfirmation) {
      try {
        const response = await fetch(
          `http://localhost:4000/location/deleteLocation/${id}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          console.log(data.message);
        } else {
          console.error("Error deleting data:", response.statusText);
        }
      } catch (error) {
        console.error("Error deleting data:", error);
      }
      getLocation();
    } else {
      console.log("Deletion cancelled by user");
    }
  };

  return (
    <>
      <LeftPanel />
      <TopNavbar />
      <div className={`${allFirstDiv}`}>
        <div className={`${allSecondDiv}`}>
          {/* tabs */}
          <div className="tabs text-[28px]">
            <p
              className={`tab tab-bordered ${
                toggleState === 1 && "tab-active"
              }`}
              onClick={() => {
                changeTab(1);
              }}
            >
              Add New Location
            </p>
            <p
              className={`tab tab-bordered ${
                toggleState === 2 && "tab-active"
              }`}
              onClick={() => {
                changeTab(2);
              }}
            >
              See Location List
            </p>
          </div>

          {toggleState === 1 ? (
            // Add new location
            <form
              className="flex flex-col gap-6 items-center"
              onSubmit={RegLocation}
            >
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="flex flex-col gap-6">
                  {/* Location name */}
                  <div className="w-64">
                    <label className="label">
                      <span className="label-text">Location Name</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Location name"
                      className={`${generalInput}`}
                      required
                      value={locationname}
                      onChange={(e) => {
                        setLocationname(e.target.value);
                      }}
                    />
                  </div>

                  {/* Rate */}
                  <div className="w-64">
                    <label className="label">
                      <span className="label-text">Rate</span>
                    </label>
                    <input
                      type="number"
                      placeholder="Rate"
                      className={`${generalInput}`}
                      required
                      value={rate}
                      onChange={(e) => {
                        setRate(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="flex flex-col gap-6">
                  <div className="w-64">
                    <label className="label">
                      <span className="label-text">
                        Rate (less than 1kg($))
                      </span>
                    </label>
                    <input
                      type="number"
                      placeholder="Type here"
                      className={`${generalInput}`}
                      required
                      value={weight1}
                      onChange={(e) => {
                        setWeight1(e.target.value);
                      }}
                    />
                  </div>

                  <div className="w-64">
                    <label className="label">
                      <span className="label-text">
                        Rate (less than 50kg($))
                      </span>
                    </label>
                    <input
                      type="number"
                      placeholder="Type here"
                      className={`${generalInput}`}
                      value={weight2}
                      onChange={(e) => {
                        setWeight2(e.target.value);
                      }}
                      required
                    />
                  </div>

                  <div className="w-64">
                    <label className="label">
                      <span className="label-text">
                        Rate (more than 50kg($))
                      </span>
                    </label>
                    <input
                      type="number"
                      placeholder="Type here"
                      className={`${generalInput}`}
                      value={weight3}
                      onChange={(e) => {
                        setWeight3(e.target.value);
                      }}
                      required
                    />
                  </div>
                </div>
              </div>
              <button className={`${positiveButton} m-auto`}>Submit</button>
            </form>
          ) : (
            // Show location table
            <div className="max-h-96 max-w-72 overflow-auto">
              <table className="max-h-96 max-w-72 overflow-auto table text-center overflow-x-auto">
                {/* head */}
                <thead>
                  <tr className="sticky top-0 bg-white">
                    <th>Edit</th>
                    <th>Delete</th>
                    <th>No.</th>
                    <th>Location</th>
                    <th>Till W1</th>
                    <th>
                      Rate <br />
                      (Weight Less than 1KG)
                    </th>
                    <th>Till W2</th>
                    <th>
                      Rate <br />
                      (Weight Less than 50KG)
                    </th>
                    <th>Till W3</th>
                    <th>
                      Rate <br />
                      (Weight more than 50KG)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {location &&
                    location.map((el, i) => {
                      return (
                        <tr className="">
                          <td className="text-[#0084FF] cursor-pointer">
                              <Link
                                onClick={() => {
                                  setLocationID(el.LocationID);
                                  setLocationName(el.locationName);
                                  document
                                    .getElementById("my_modal_5")
                                    .showModal();
                                }}
                              >
                                <EditIcon />
                                <p className="hover:underline">Edit</p>
                              </Link>
                            </td>
                          <td className="text-[#DB1E1E] cursor-pointer">
                            <button
                              onClick={() => {
                                handleDelete(el.LocationID, el.locationName);
                              }}
                            >
                              <DeleteIcon />
                            </button>
                          </td>
                          <td className="">{i + 1}</td>
                          <td>{el.locationName}</td>
                          <td className="">{el.tillW1}</td>
                          <td>{el.val1}</td>
                          <td className="">{el.tillW2}</td>
                          <td>{el.val2}</td>
                          <td className="">{el.tillW3}</td>
                          <td>{el.val3}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          )}
        </div>
        <div className="h-20" />
      </div>
      <dialog id="my_modal_5" className="modal m-auto w-11/12 md:w-1/2">
        <div className="modal-box flex flex-col gap-5">
          <div className="flex flex-col gap-3 text-lg">
            <p className="font-bold flex justify-between items-center">
              Updating Records for {locationName}
              <button
                className="w-max hover:tooltip hover:tooltip-bottom"
                data-tip="Close"
                onClick={closeModal}
              >
                <CloseIcon/>
              </button>
            </p>
            <p className="text-sm text-[#9FA1A6]">
              Press ESC key or click close button to close
            </p>
          </div>
         <EditLocation locationID={locationID}/>
        </div>
      </dialog>
    </>
  );
};

export default Location;
